import anime from "animejs";

const d = document,
    menuTrigger = d.getElementsByClassName("menu-icon"),
    menu = d.getElementById("menu-over");

const menuEl = document.querySelector('.menu .view, .menu .hidden')
const openAnimation = anime.timeline({
    autoplay: false,
    easing: 'easeOutElastic'
})

for (let trigger of menuTrigger) {
    trigger.addEventListener("click", (event) => {
        console.log("EVENT");
        menu.classList.remove('none')
        menu.classList.toggle("hidden");
        menu.classList.toggle("view");
        if (menu.classList.contains('view'))
            openAnimation.play()


    })
}

const invisible = document.querySelector('nav .typo .secondLine')
const firstDot = document.querySelector('nav .typo .firstLine .dot')
invisible.style.display = 'none';

openAnimation.add({
    targets: menuEl,
    translateX: ['100vw', 0],
    backgroundColor: 'red',
    duration: 500,
    easing: 'easeInOutSine',
    begin: function(anim) {
        if (anim.began) {
            claimAnimation.play()
            console.log('MENU began')
        }
    }

})
openAnimation.add({
    targets: '.menu ul li',
    translate: ['100vw', 0],
    opacity: [0, 1],
    delay: anime.stagger(100)
}, '-=1500')

const claimAnimation = anime.timeline({
    duration: 5000,
    autoplay: false,
});

claimAnimation.add({
    targets: 'nav .typo .claim-content.first',
    width: [0, '298px'],
    easing: 'steps(' + 38 + ')',
    duration: 2500,
    complete: function(anim) {
        if (anim.completed) {
            claimAnimationdotSecond.play()
            invisible.style.display = 'flex'
        }
    },
    begin: function(anim) {
        if (anim.began) {
            claimAnimationdotFirst.play()
        }
    }
})
claimAnimation.add({
    targets: 'nav .typo .claim-content.second',
    width: ['0px', '341px'],
    easing: 'steps(' + 41 + ')',
    duration: 2500,
})

const claimAnimationdotFirst = anime({
    targets: '.content-holder .firstLine .dot',
    opacity: [0, 1],
    loop: 10,
    direction: 'alternate',
    easing: 'easeInOutSine',
    duration: 250,
    autoplay: false,

})
const claimAnimationdotSecond = anime({
    targets: 'nav .typo .content-holder .secondLine .dot',
    opacity: [0, 1],
    loop: 10,
    direction: 'alternate',
    easing: 'easeInOutSine',
    duration: 250,
    autoplay: false
})